<template>
  <!-- eslint-disable max-len -->
  <div class="accessibility-icon">
    <svg viewBox="0 0 24 24">
      <path fill="currentColor" d="M21,9H15V22H13V16H11V22H9V9H3V7H21M12,2A2,2 0 0,1 14,4A2,2 0 0,1 12,6C10.89,6 10,5.1 10,4C10,2.89 10.89,2 12,2Z" />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'AccessibilityIcon',
};
</script>













































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import AccessibilityIcon from './AccessibilityIcon.vue';
import AccountIcon from './AccountIcon.vue';
import AlertCircleIcon from './AlertCircle.vue';
import ArrowIcon from './ArrowIcon.vue';
import CalendarClockIcon from './CalendarClockIcon.vue';
import CalendarIcon from './CalendarIcon.vue';
import CalendarStarIcon from './CalendarStarIcon.vue';
import CheckMarkIcon from './CheckMarkIcon.vue';
import CloseIcon from './CloseIcon.vue';
import CrosshairsIcon from './CrosshairsIcon.vue';
import CurrencyUsdIcon from './CurrencyUsdIcon.vue';
import EraArrowIcon from './EraArrowIcon.vue';
import HeartIcon from './HeartIcon.vue';
import InformationIcon from './InformationIcon.vue';
import MapMarkerIcon from './MapMarkerIcon.vue';
import MenuIcon from './MenuIcon.vue';
import QsLogoIcon from './QsLogoIcon.vue';

@Component({
  components: {
    AccessibilityIcon,
    ArrowIcon,
    AccountIcon,
    AlertCircleIcon,
    CalendarClockIcon,
    CalendarIcon,
    CalendarStarIcon,
    CheckMarkIcon,
    CloseIcon,
    CrosshairsIcon,
    CurrencyUsdIcon,
    EraArrowIcon,
    HeartIcon,
    InformationIcon,
    MapMarkerIcon,
    MenuIcon,
    QsLogoIcon,
  },
})
export default class QsIcon extends Vue {
  @Prop({ required: false, type: String, default: 'qs-logo' }) icon!: string;
  @Prop({ required: false, type: Boolean, default: false }) loading!: boolean;
  @Prop({ required: false, type: String, default: 'md' }) size!: string;
}

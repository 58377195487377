import Cookies from 'js-cookie';
import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import VuexPersistence from 'vuex-persist';
import axios from 'axios';

import { RootState } from 'qs_vuetify/src/types/states';

import AuthModule from 'qs_vuetify/src/store/auth/index';
import GlobalViewModule from 'qs_vuetify/src/store/views/global';

import { ViewState } from '@/types/states';
import { MouvementEvent } from '@/types/models';

import { events } from './events';
import { identity } from './identity';
import { instances } from './instances';
import { newEventState } from './newEventState';
import { virtualEvents } from './virtualEvents';
import view from './view';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence<RootState>({
  key: 'qs_mouvement_v2',
  storage: window.localStorage,
  reducer(state) {
    return {
      global: state.global,
      newEventState: state.newEventState,
      view: {
        ...(state.view as ViewState),
        geolocation: null,
        notifications: [],
        params: {},
      },
    };
  },
});

const vuexCookie = new VuexPersistence<RootState>({
  key: 'qs_auth_v2',
  restoreState: (key) => Cookies.getJSON(key),
  saveState: async (key, state) => {
    await Cookies.set(key, state, {
      expires: 3,
      secure: process.env.NODE_ENV !== 'development',
      domain: process.env.VUE_APP_COOKIE_DOMAIN,
      path: '/',
      sameSite: 'Lax',
    });
  },
  reducer(state) {
    return {
      auth: {
        token: state.auth?.token,
        refreshToken: state.auth?.refreshToken,
        v1Token: state.auth?.v1Token,
      },
    };
  },
});

const storeOptions: StoreOptions<RootState> = {
  state: {
    version: '0.1.0',
  },
  modules: {
    auth: AuthModule,
    events,
    global: GlobalViewModule,
    identity,
    instances,
    newEventState,
    virtualEvents,
    view,
  },
  plugins: [vuexLocal.plugin, vuexCookie.plugin],
};

const store = new Vuex.Store(storeOptions);

axios.interceptors.request.use((config) => {
  if (store.state.auth && store.state.auth.token) {
    // eslint-disable-next-line
    config.headers.common.Authorization = `Bearer ${store.state.auth.token}`;
  }
  return config;
});

export default store;

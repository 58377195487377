

































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import QsBaseModal from '../QsBaseModal.vue';

@Component({
  components: {
    QsBaseModal,
  },
})
export default class AdhesionOnboarding extends Vue {
  @Prop({ required: true, type: Boolean, default: false }) value!: boolean;

  complete() {
    this.$emit('complete', true);
  }
}

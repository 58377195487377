<template>
  <div
    :class="`app-notification alert alert-${type} shadow-lg mb-3 opacity-${active ? '1' : '0'}`"
  >
    <div>
      <svg v-if="(icon || type) === 'error'" xmlns="http://www.w3.org/2000/svg" class="stroke-current flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
      <svg v-else-if="(icon || type) === 'success'" xmlns="http://www.w3.org/2000/svg" class="stroke-current flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
      <svg v-else-if="(icon || type) === 'warning'" xmlns="http://www.w3.org/2000/svg" class="stroke-current flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" /></svg>
      <span v-html="message" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppNotification',
  props: {
    active: {
      required: true,
      type: Boolean,
    },
    icon: {
      required: false,
      type: String,
    },
    message: {
      required: true,
      type: String,
    },
    type: {
      required: true,
      type: String,
    },
  },
};
</script>

<style lang="scss">
</style>

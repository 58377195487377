import { ActionTree, GetterTree, Module } from 'vuex';

import { Event } from 'qs_vuetify/src/types/models';
import { RootState } from 'qs_vuetify/src/types/states';

import {
  buildRestActions,
  buildRestGetters,
  buildRestMutations,
  buildRestState,
} from 'qs_vuetify/src/plugins/rest';

import { EventsRestState, UserContact } from '@/types/states';
import axios from 'axios';

export const eventsState: EventsRestState = {
  ...buildRestState<Event>('events', {
    path: 'mouvement',
  }),
};

export const getters: GetterTree<EventsRestState, RootState> = {
  ...buildRestGetters<Event, EventsRestState>(),
};

export const actions: ActionTree<EventsRestState, RootState> = {
  ...buildRestActions<Event, EventsRestState>(),
};

export const mutations = buildRestMutations<Event, EventsRestState>();

export const virtualEvents: Module<EventsRestState, RootState> = {
  namespaced: true,
  state: eventsState,
  getters,
  actions,
  mutations,
};

import { ActionTree, GetterTree, Module } from 'vuex';

import { Event } from 'qs_vuetify/src/types/models';
import { RootState } from 'qs_vuetify/src/types/states';

import {
  buildRestActions,
  buildRestGetters,
  buildRestMutations,
  buildRestState,
} from 'qs_vuetify/src/plugins/rest';

import { EventsRestState, UserContact } from '@/types/states';
import axios from 'axios';

export const eventsState: EventsRestState = {
  ...buildRestState<Event>('events', {
    path: 'mouvement',
  }),
};

export const getters: GetterTree<EventsRestState, RootState> = {
  ...buildRestGetters<Event, EventsRestState>(),
};

export const actions: ActionTree<EventsRestState, RootState> = {
  ...buildRestActions<Event, EventsRestState>(),
  async register({ commit, state }, data: UserContact) {
    if (!state.item) {
      return;
    }

    const { id: eventId } = state.item;
    const url = `/mouvement/${eventId}/register`;

    try {
      await axios.put(url, data);
    } catch (e) {
      const error: any = e;
      if (error.response && error.response.data) {
        commit('error', {
          ...error.response.data,
          request: {
            url: `PUT ${url}`,
          },
          status: error.response.status,
        });
      }
    }
  },
  async sendEmailInvite({ commit, state }, email: string) {
    const url = `/mouvement/${state.item?.id}/invite/${email}`;
    try {
      await axios.put(url);
    } catch (e) {
      const error: any = e;
      if (error.response && error.response.data) {
        commit('error', {
          ...error.response.data,
          request: {
            url: `PUT ${url}`,
          },
          status: error.response.status,
        });
      }

      throw e;
    }
  },
  async unregister({ commit }, id: number) {
    const url = `/mouvement/${id}/unregister`;

    try {
      await axios.put(url);
    } catch (e) {
      const error: any = e;
      if (error.response && error.response.data) {
        commit('error', {
          ...error.response.data,
          request: {
            url: `PUT ${url}`,
          },
          status: error.response.status,
        });
      }
    }
  },
};

export const mutations = buildRestMutations<Event, EventsRestState>();

export const events: Module<EventsRestState, RootState> = {
  namespaced: true,
  state: eventsState,
  getters,
  actions,
  mutations,
};

import Vue from 'vue';
import VueRouter, { Route, RouteConfig } from 'vue-router';

import store from '../store';

const EventEdit = () => import('../views/EventEdit.vue');
const EventModal = () => import('../views/EventModal.vue');
const EventModalInvite = () => import('../views/EventModal/EventModalInvite.vue');
const EventModalEventView = () => import('../views/EventModal/EventModalEventView.vue');
const EventModalNewEvent = () => import('../views/EventModal/EventModalNewEvent.vue');
const EventModalRegistration = () => import('../views/EventModal/EventModalRegistration.vue');
const EventsMap = () => import('../views/EventsMap.vue');
const InstanceFinder = () => import('../views/InstanceFinder.vue');
const VirtualEvents = () => import('../views/VirtualEvents.vue');
const UserEvents = () => import('../views/UserEvents.vue');

Vue.use(VueRouter);

const eventsPageParams = {
  fields: [
    'accessibility',
    'contacts_count',
    'description',
    'instances.name',
    'location.google_place_address',
    'location.point',
    'location_name',
    'organized_by_user_id',
    'title',
    'start_at',
    'visibility',
  ].join(','),
  order: 'start_at',
  per_page: 100,
  status: 'current,upcoming',
};

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Accueil',
    redirect: (to: Route) => ({ name: 'Carte', query: to.query }),
  },
  {
    path: '/carte',
    name: 'Carte',
    component: EventsMap,
    meta: {
      data: {
        events: {
          loadPage: {
            manual: true,
            params: { ...eventsPageParams, location: 1 },
          },
        },
      },
      title: 'Carte',
    },
    children: [
      {
        path: 'nouveau',
        component: EventModal,
        props: () => ({ id: 'nouveau' }),
        children: [
          {
            path: '',
            name: 'EventModalNewEvent',
            component: EventModalNewEvent,
            meta: {
              data: {
                events: {
                  loadEmpty: {},
                },
              },
              title: 'Nouvel événement',
            },
          },
        ],
      },
      {
        path: ':id',
        component: EventModal,
        props: true,
        children: [
          {
            path: '',
            name: 'EventModalEventView',
            component: EventModalEventView,
            props: true,
            meta: {
              data: {
                events: {
                  retrieve: {
                    conditional(vm: Vue) {
                      return parseInt(vm.$route.params.id, 10) !== vm.$store.state.events.item?.id;
                    },
                    contextual(vm: Vue) {
                      return {
                        id: vm.$route.params.id,
                      };
                    },
                    params: { ...eventsPageParams, location: 1 },
                  },
                  loadPage: {
                    manual: true,
                    params: { ...eventsPageParams, location: 1 },
                  },
                },
              },
              menus: [],
              title: 'Événement',
            },
          },
          {
            path: 'participer',
            name: 'Participer',
            component: EventModalRegistration,
            props: true,
            meta: {
              data: {
                events: {
                  retrieve: {
                    conditional(vm: Vue) {
                      return vm.$route.params.id !== 'nouveau'
                        && parseInt(vm.$route.params.id, 10) !== vm.$store.state.events.item?.id;
                    },
                    contextual(vm: Vue) {
                      return {
                        id: vm.$route.params.id,
                      };
                    },
                    params: { ...eventsPageParams },
                  },
                },
              },
              title: 'Participer',
            },
          },
          {
            path: 'inviter',
            name: 'Inviter',
            component: EventModalInvite,
            props: true,
            meta: {
              data: {
                events: {
                  retrieve: {
                    conditional(vm: Vue) {
                      return vm.$route.params.id !== 'nouveau'
                        && parseInt(vm.$route.params.id, 10) !== vm.$store.state.events.item?.id;
                    },
                    contextual(vm: Vue) {
                      return {
                        id: vm.$route.params.id,
                      };
                    },
                    params: { ...eventsPageParams },
                  },
                },
              },
              title: 'Inviter',
            },
          },
        ],
      },
    ],
  },
  {
    path: '/carte/:id/modifier',
    name: 'EventEdit',
    component: EventEdit,
    props: true,
    meta: {
      auth: true,
      data: {
        events: {
          loadPage: {
            manual: true,
            params: { ...eventsPageParams, location: 1 },
          },
          retrieve: {
            conditional(vm: Vue) {
              return vm.$route.params.id !== 'nouveau'
                && parseInt(vm.$route.params.id, 10) !== vm.$store.state.events.item?.id;
            },
            contextual(vm: Vue) {
              return {
                id: vm.$route.params.id,
              };
            },
          },
        },
      },
      menus: [],
      title: 'Événement',
    },
  },
  {
    path: '/evenements-virtuels',
    name: 'Événements virtuel',
    component: VirtualEvents,
    meta: {
      data: {
        virtualEvents: {
          index: {
            params: {
              ...eventsPageParams,
              accessibility: 'virtual,virtual_with_lsq',
            },
          },
        },
      },
    },
    children: [
      {
        path: ':id',
        name: 'Événement virtuel',
        component: EventModalEventView,
        props: true,
        meta: {
          data: {
            events: {
              retrieve: {
                conditional(vm: Vue) {
                  return parseInt(vm.$route.params.id, 10) !== vm.$store.state.events.item?.id;
                },
                contextual(vm: Vue) {
                  return {
                    id: vm.$route.params.id,
                  };
                },
                params: { ...eventsPageParams },
              },
            },
            virtualEvents: {
              index: {
                params: {
                  ...eventsPageParams,
                  accessibility: 'virtual,virtual_with_lsq',
                },
                contextual(vm: Vue) {
                  if (vm.$store.state.view.virtualParams && vm.$store.state.view.virtualParams.q) {
                    return { q: vm.$store.state.view.virtualParams.q };
                  }

                  return {};
                },
              },
            },
          },
        },
      },
      {
        path: ':id/participer',
        name: 'Participer',
        component: EventModalRegistration,
        props: true,
        meta: {
          data: {
            events: {
              retrieve: {
                conditional(vm: Vue) {
                  return vm.$route.params.id !== 'nouveau'
                    && parseInt(vm.$route.params.id, 10) !== vm.$store.state.events.item?.id;
                },
                contextual(vm: Vue) {
                  return {
                    id: vm.$route.params.id,
                  };
                },
                params: { ...eventsPageParams },
              },
            },
          },
          title: 'Participer',
        },
      },
    ],
  },
  {
    path: '/mes-evenements',
    name: 'Mes événements',
    component: UserEvents,
    meta: {
      auth: true,
    },
  },
  {
    path: '/trouver-mon-association',
    name: 'Trouver mon association',
    component: InstanceFinder,
    meta: {
      title: 'Trouver mon association',
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  store.commit('global/reset');
  next();
});

export default router;

import { Module } from 'vuex';
import { RootState } from 'qs_vuetify/src/types/states';

import { NewEventState } from '@/types/states';

export const newEventState: Module<NewEventState, RootState> = {
  namespaced: true,
  state: {
    item: {
      description: '',
      duration: 'PT1H',
      location: null,
      location_name: '',
      start_at: '',
      title: '',
      accessibility: 'accessible',
      organized_by_user: {
        email: '',
        first_name: '',
        last_name: '',
        home_phone: '',
        postal_code: '',
      },
    },
  },
  getters: {
    item(state) {
      return state.item;
    },
  },
  mutations: {
    setItem(state, item) {
      state.item = item;
    },
    reset(state) {
      state.item = {
        description: '',
        duration: 'PT1H',
        location: null,
        location_name: '',
        start_at: '',
        title: '',
        accessibility: 'accessible',
        organized_by_user: {
          email: '',
          first_name: '',
          last_name: '',
          home_phone: '',
          postal_code: '',
        },
      };
    },
  },
};

export default newEventState;

import dayjs from 'qs_vuetify/src/plugins/dayjs';

import { LatLngLiteral } from '@/types/components';
import { MouvementEvent } from '@/types/models';

export const eventColor = (event: MouvementEvent, userId: number | null): 'orange' | 'blue' | 'green' => {
  if (event.organized_by_user_id === userId) {
    return 'green';
  }

  if (event.instances.length > 0) {
    return 'orange';
  }

  return 'blue';
};

export const eventImportant = (event: MouvementEvent): string => {
  if (event.start_at.substr() === dayjs().format('YYYY-MM-DD')) {
    return '-important';
  }

  return '';
};

export const eventMarkerUrl = (event: MouvementEvent, userId: number | null = null): string => {
  const filename = [
    eventColor(event, userId),
    eventImportant(event),
    event.participating ? 'checked' : null,
    'calendar',
  ].filter((p: string | null) => !!p).join('-');

  return `/markers/${filename}.png`;
};

export const toLatLngLiteral = (latLng: number[]): LatLngLiteral => ({ lat: latLng[0], lng: latLng[1] });

export default {
  eventMarkerUrl,
  eventImportant,
  eventColor,
  toLatLngLiteral,
};

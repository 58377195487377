





































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import axios from 'axios';

import { User } from 'qs_vuetify/src/types/models';

import AppNotification from '@/components/AppNotification.vue';
import QsLogo from '@/components/logo/QsLogo.vue';
import BurgerMenu from '@/components/Icons/MenuIcon.vue';
import QsIcon from '@/components/Icons/QsIcon.vue';
import QsMouvement from '@/components/logo/QsMouvement.vue';
import AdhesionOnboarding from './components/Onboardings/AdhesionOnboarding.vue';
import store from './store';

const auth = namespace('auth');
const view = namespace('view');

@Component({
  components: {
    AdhesionOnboarding,
    AppNotification,
    QsLogo,
    BurgerMenu,
    QsIcon,
    QsMouvement,
  },
})
export default class App extends Vue {
  @auth.Action loadUser!: (arg: string) => void;
  @auth.Action logout!: () => void;
  @auth.Getter token!: string | null;
  @auth.Getter user!: User | null;

  @view.Getter adhesionOnboardingComplete!: boolean;
  @view.Getter notifications!: Notification[];
  @view.Getter virtualEventsCount!: number;
  @view.Mutation setAdhesionOnboardingComplete!: any;

  returnLink = 'https://quebecsolidaire.net';
  returnLinkText = 'Retour au site national';

  get showAdhesionOnboardingModal(): boolean {
    if (this.$route.query?.onboard) {
      return !this.adhesionOnboardingComplete;
    }

    return false;
  }

  // eslint-disable-next-line class-methods-use-this
  get userFields(): string {
    return [
      'email',
      [
        'name',
        'rights_slugs',
      ].map((f: string) => `inherited_instances.${f}`).join(','),
      [
        'contribution_current_year',
        'district_name',
        'first_name',
        [
          'accessibility',
          'created_at',
          'description',
          'duration',
          'end_at',
          'instances.name',
          'intention',
          'location.google_place_address',
          'location.point',
          'location_name',
          'organized_by_user_id',
          'organized_by_user.contact_name',
          'start_at',
          'title',
          'updated_at',
        ].map((f: string) => `future_events.${f}`),
        'home_phone',
        'last_name',
        'postal_code',
        'status',
      ].flat().map((f: string) => `contact.${f}`).join(','),
    ].flat().join(',');
  }

  async mounted() {
    if (!window.campagneLoaded) {
      window.campagne.start();
    }
    window.campagneLoaded = true;

    if (this.token && !this.user) {
      try {
        await this.loadUser(this.userFields);
      } catch (e) {
        await this.logout();

        if (this.$route.name !== 'Home' && this.$route.name !== 'Carte') {
          this.$store.commit('view/addNotification', {
            type: 'error',
            message: 'Vous n\'êtes pas connecté·e',
          });
          this.$router.push('/');
        }
      }
    } else if (!this.token && this.$route?.meta?.auth) {
      this.$router.push('/');
    }

    this.$store.dispatch('view/fetchVirtualEventsCount');
  }
}

<template>
  <!-- eslint-disable max-len -->
  <div class="close-icon" :class="{ outlined, }">
    <svg preserverAspectRatio viewBox="0 0 24 24">
      <path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'CloseIcon',
  props: {
    outlined: {
      default: false,
      required: false,
      type: Boolean,
    },
  },
};
</script>

<style lang="scss">
.close-icon {
  fill: black;
  background-color: transparent;

  &.outlined {
    border: 1px solid black;
    border-radius: 100%;
    transition: background-color 0.25s ease-in-out, fill 0.25s ease-in-out;

    &:hover {
      fill: white;
      background-color: black;
    }
  }
}
</style>

import Vue from 'vue';
import GmapVue from 'gmap-vue';
import VCalendar from 'v-calendar';

import 'qs_vuetify/src/plugins/vueHead';
import 'qs_vuetify/src/plugins/geolocation';
import dayjs from 'qs_vuetify/src/plugins/dayjs';
import filters from 'qs_vuetify/src/plugins/filters';
import helpers from 'qs_vuetify/src/plugins/helpers';

import App from './App.vue';
import mouvementHelpers from './helpers';
import router from './router';
import store from './store';

import './main.css';

Vue.config.productionTip = false;

declare global {
    interface Window {
      campagneLoaded?: boolean;
      campagne: {
        start: () => void;
      };
    }
}

declare module 'vue/types/vue' {
  interface Vue { // eslint-disable-line no-shadow
    $dayjs: typeof dayjs;
    $filters: typeof filters;
    $helpers: typeof helpers;
    $getLocation: (options?: Record<string, any>) => Promise<any>;
  }
}

Object.keys(filters).forEach((f) => Vue.filter(f, filters[f]));

Vue.prototype.$dayjs = dayjs;
Vue.prototype.$filters = filters;
Vue.prototype.$helpers = {
  ...helpers,
  ...mouvementHelpers,
};

Vue.use(GmapVue, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
    libraries: 'places',
  },
  autoBindAllEvents: false,
  installComponents: true,
  dynamicLoad: false,
});

Vue.use(VCalendar);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

<template>
  <!-- eslint-disable max-len  -->
  <div class="qs-logo-icon">
    <svg width="11" height="11" viewBox="0 0 11 11" fill="black" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.77576 5.94046C6.20014 5.84036 6.46407 5.41069 6.36523 4.98078C6.26641 4.55089 5.84226 4.28357 5.41788 4.38368C4.99349 4.48379 4.72959 4.91346 4.82842 5.34336C4.92724 5.77325 5.35138 6.04057 5.77576 5.94046Z" fill="black"/>
      <path d="M3.735 1.53798C3.74738 1.54116 3.75999 1.54049 3.77147 1.53684C4.13613 1.4191 4.52467 1.38077 4.91613 1.42777C4.99248 1.4369 5.06817 1.44922 5.14248 1.4645C5.77157 1.5941 6.32926 1.94115 6.73107 2.4577C7.18043 3.03542 7.38088 3.75573 7.29552 4.48608C7.23718 4.9853 6.99034 5.43162 6.60066 5.74261C6.54344 5.78824 6.48399 5.8302 6.42296 5.86853C6.40855 5.87721 6.39751 5.89158 6.393 5.90938C6.38423 5.94498 6.40562 5.98079 6.44052 5.98968C6.45044 5.99221 6.46036 5.99222 6.46959 5.99037C7.05249 5.86672 7.56491 5.53633 7.9246 5.04852C8.73164 3.954 8.50955 2.39884 7.42932 1.58155C6.96644 1.23064 6.41666 1.00863 5.83982 0.939271C5.09025 0.849602 4.34943 1.01685 3.71697 1.4134C3.7021 1.4223 3.69062 1.43713 3.68611 1.45538C3.6771 1.49188 3.69895 1.52885 3.735 1.53798Z" fill="black"/>
      <path d="M3.95841 7.10198C3.67551 6.84097 3.44824 6.51925 3.29283 6.15238C3.26243 6.08073 3.23518 6.00839 3.21108 5.93537C3.00746 5.31888 3.02525 4.65585 3.26581 4.04525C3.53496 3.36191 4.05076 2.82595 4.7179 2.53573C5.17378 2.33745 5.67877 2.33083 6.13961 2.51702C6.2074 2.5444 6.27292 2.57543 6.33623 2.61011C6.35087 2.61832 6.36867 2.62106 6.386 2.61604C6.4207 2.60601 6.44097 2.56927 6.43105 2.53413C6.42834 2.52432 6.4234 2.51542 6.41707 2.50834C6.02001 2.05864 5.48124 1.77435 4.88436 1.70271C3.54487 1.54184 2.32634 2.51429 2.16756 3.87048C2.09909 4.45187 2.184 5.04532 2.41328 5.58606C2.71105 6.2886 3.22458 6.85489 3.8798 7.21149C3.89488 7.22019 3.91314 7.22269 3.93116 7.21765C3.96697 7.20739 3.98769 7.16953 3.97733 7.13347C3.97395 7.12093 3.96719 7.11021 3.95841 7.10198Z" fill="black"/>
      <path d="M8.75549 4.16957C8.75526 4.15201 8.74827 4.13467 8.73499 4.12144C8.7082 4.09519 8.66561 4.09589 8.63995 4.12303C8.63094 4.1324 8.62531 4.14378 8.6226 4.15542C8.54084 4.53418 8.37935 4.89421 8.14352 5.21412C8.09757 5.27663 8.04915 5.33662 7.9987 5.39435C7.57345 5.88126 6.99774 6.19705 6.35538 6.29126C5.63685 6.3967 4.92062 6.21232 4.33905 5.77221C3.94151 5.47149 3.68339 5.03181 3.61222 4.53441C3.60186 4.46138 3.59556 4.38815 3.59353 4.31559C3.5933 4.29871 3.58654 4.28184 3.57371 4.26905C3.5478 4.24349 3.50636 4.24418 3.48114 4.27066C3.47415 4.27796 3.46897 4.28661 3.46604 4.29599C3.28022 4.86935 3.30657 5.48403 3.54375 6.04327C3.76988 6.5767 4.16045 6.98808 4.62961 7.24411C4.67354 7.26806 4.67782 7.3292 4.63885 7.36115C4.26563 7.66664 4.08455 8.17065 4.0163 8.60556C4.00571 8.67376 4.08612 8.71895 4.13657 8.67241C4.4037 8.42392 4.79426 8.25326 5.3033 8.31967C6.10626 8.42415 6.80405 8.85448 7.36893 10.0731C7.40294 10.1466 7.51196 10.1185 7.50656 10.0375C7.43359 8.92429 7.05406 8.04905 6.51394 7.54275C6.47452 7.50579 6.48872 7.44056 6.53983 7.42388C6.61215 7.40038 6.68422 7.37322 6.75541 7.3422C7.28674 7.11177 7.7514 6.74032 8.09916 6.2687C8.55096 5.65629 8.77823 4.92251 8.75549 4.16957Z" fill="black"/>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'QsLogoIcon',
};
</script>

<style lang="scss" scoped>

</style>














































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import QsIcon from '@/components/Icons/QsIcon.vue';

@Component({
  components: {
    QsIcon,
  },
})
export default class QsBaseModal extends Vue {
  @Prop({ required: false, type: Boolean, default: false }) hideMobileCloseButton!: boolean;
  @Prop({ required: false, type: Boolean, default: false }) persistent!: boolean;
  @Prop({ required: true, type: Boolean, default: false }) value!: boolean;

  closeModal() {
    this.$emit('click:close');
  }
}

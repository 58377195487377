import { Module } from 'vuex';
import axios from 'axios';

import { RootState } from 'qs_vuetify/src/types/states';

import { Notification } from '@/types/components';
import { ViewState } from '@/types/states';

const view: Module<ViewState, RootState> = {
  namespaced: true,
  state: {
    adhesionOnboardingComplete: false,
    calendarParams: {},
    center: {
      lat: 48.0228586,
      lng: -72.8304903,
    },
    geolocation: null,
    mapParams: {},
    params: {
      fields: [
        'accessibility',
        'accessibility_notes',
        'contacts.email',
        'contacts.full_name',
        'contacts.home_phone',
        'contacts_count',
        'created_at',
        'description',
        'description',
        'duration',
        'end_at',
        'instances.name',
        'instance_id',
        'location.google_place_address',
        'location.point',
        'location_name',
        'organized_by_user.contact_name',
        'organized_by_user_id',
        'start_at',
        'title',
        'updated_at',
      ].join(','),
    },
    notifications: [],
    postalCode: null,
    postalCodeCenter: null,
    postalCodeDistricts: [],
    postalCodeLoading: false,
    virtualParams: {},
    virtualEventsCount: 0,
    zoom: 7,
  },
  getters: {
    adhesionOnboardingComplete(state) {
      return state.adhesionOnboardingComplete;
    },
    calendarParams(state) {
      return state.calendarParams;
    },
    center(state) {
      return state.center;
    },
    geolocation(state) {
      return state.geolocation;
    },
    mapParams(state) {
      return state.mapParams;
    },
    notifications(state) {
      return state.notifications;
    },
    params(state) {
      return state.params;
    },
    postalCode(state) {
      return state.postalCode;
    },
    postalCodeCenter(state) {
      return state.postalCodeCenter;
    },
    postalCodeDistricts(state) {
      return state.postalCodeDistricts;
    },
    postalCodeLoading(state) {
      return state.postalCodeLoading;
    },
    virtualParams(state) {
      return state.virtualParams;
    },
    virtualEventsCount(state) {
      return state.virtualEventsCount;
    },
    zoom(state) {
      return state.zoom;
    },
  },
  actions: {
    async fetchPostalCodeCenter({ commit }, postalCode: string) {
      commit('postalCodeLoading', true);

      try {
        const { data } = await axios.get(`/mouvement/code_postal/${postalCode.replaceAll(' ', '')}`);

        commit('postalCodeDistricts', data.districts);

        if (Array.isArray(data.centroid) && data.centroid.length === 2) {
          commit('postalCodeCenter', {
            lat: data.centroid[0],
            lng: data.centroid[1],
          });
        }
      } catch (e) {
        commit('postalCode', '');
        commit('addNotification', {
          type: 'warning',
          message: "Ce code postal n'est pas dans notre base de données.",
        });
      } finally {
        commit('postalCodeLoading', false);
      }
    },
    async fetchVirtualEventsCount({ commit }) {
      const { data: { total } } = await axios.get('/mouvement', {
        params: {
          accessibility: 'virtual,virtual_with_lsq',
          order: 'start_at',
          per_page: 1,
          status: 'current,upcoming',
        },
      });

      commit('virtualEventsCount', total);
    },
  },
  mutations: {
    addNotification(state, notification: Omit<Notification, 'active'>) {
      const newNotification: Notification = {
        ...notification,
        active: true,
      };
      state.notifications.unshift(newNotification);

      const timeout = newNotification.timeout || 2500;
      setTimeout(() => {
        const n = state.notifications.find((i) => i === newNotification);
        if (n) {
          const deactivatedNotification = {
            ...n,
            active: false,
          };
          state.notifications.splice(state.notifications.indexOf(newNotification), 1, deactivatedNotification);

          setTimeout(() => {
            state.notifications.splice(state.notifications.indexOf(deactivatedNotification), 1);
          }, 1000);
        }
      }, timeout);
    },
    setAdhesionOnboardingComplete(state, payload) {
      state.adhesionOnboardingComplete = payload;
    },
    calendarParams(state, calendarParams) {
      state.calendarParams = calendarParams;
    },
    center(state, center) {
      state.center = center;
    },
    geolocation(state, geolocation) {
      state.geolocation = geolocation;
    },
    mapParams(state, mapParams) {
      state.mapParams = mapParams;
    },
    postalCode(state, postalCode) {
      if (postalCode === null || postalCode === undefined) {
        state.postalCode = '';
        state.postalCodeCenter = null;
        return;
      }

      if (typeof postalCode === 'object') {
        state.postalCode = '';
        state.postalCodeCenter = null;
        return;
      }

      const newPostalCode = `${postalCode}`;
      if (state.postalCode !== newPostalCode) {
        state.postalCodeCenter = null;
      }
      state.postalCode = newPostalCode;
    },
    postalCodeCenter(state, postalCodeCenter) {
      state.postalCodeCenter = postalCodeCenter;
    },
    postalCodeDistricts(state, postalCodeDistricts) {
      state.postalCodeDistricts = postalCodeDistricts;
    },
    postalCodeLoading(state, postalCodeLoading) {
      state.postalCodeLoading = postalCodeLoading;
    },
    virtualParams(state, virtualParams) {
      state.virtualParams = virtualParams;
    },
    virtualEventsCount(state, virtualEventsCount) {
      state.virtualEventsCount = virtualEventsCount;
    },
    zoom(state, zoom) {
      state.zoom = zoom;
    },
  },
};

export default view;

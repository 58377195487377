<template>
  <!-- eslint-disable max-len -->
  <div class="calendar-star-icon">
    <svg viewBox="0 0 24 24">
      <path fill="currentColor" d="M19 19H5V8H19M16 1V3H8V1H6V3H5C3.9 3 3 3.9 3 5V19C3 20.11 3.9 21 5 21H19C20.11 21 21 20.11 21 19V5C21 3.9 20.11 3 19 3H18V1M10.88 12H7.27L10.19 14.11L9.08 17.56L12 15.43L14.92 17.56L13.8 14.12L16.72 12H13.12L12 8.56L10.88 12Z" />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'CalendarStarIcon',
};
</script>

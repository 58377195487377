import { Module } from 'vuex';
import { RootState } from 'qs_vuetify/src/types/states';

import { IdentityState } from '@/types/states';

export const identity: Module<IdentityState, RootState> = {
  namespaced: true,
  state: {
    contact: {
      email: '',
      first_name: '',
      home_phone: '',
      last_name: '',
      postal_code: '',
    },
  },
  getters: {
    contact(state) {
      return state.contact;
    },
  },
  actions: {
  },
  mutations: {
    contact(state, payload) {
      state.contact = { ...payload };
    },
  },
};

export default identity;

<template>
  <!-- eslint-disable max-len -->
  <div class="era-arrow-icon">
    <svg viewBox="0 0 300 300">
      <path d="M192.67,45.43c0,52.65,42.82,95.48,95.45,95.48v18.19c-52.64,0-95.45,42.83-95.45,95.48h-18.18c0-39.98,20.76-75.2,52.06-95.48H11.88v-18.19H226.55c-31.3-20.28-52.06-55.5-52.06-95.48h18.18Z"/>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'EraArrowIcon',
};
</script>
